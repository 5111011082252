import {useLazyQuery} from '@apollo/client';
import {PppQueryFormDataArgs, PppSopDataInput, Query} from '@eon.cz/apollo13-graphql-web';
import {yupResolver} from '@hookform/resolvers/yup';
import {ConfirmationNumberOutlined, ExplicitOutlined, InputOutlined, LocationCityOutlined} from '@mui/icons-material';
import {Grid, IconButton, Typography} from '@mui/material';
import {saveAs} from 'file-saver';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {useCommonAction} from '../../../common/action/CommonAction';
import {LoadingDialog} from '../../../common/components/dialogs/LoadingDialog';
import {FormInput} from '../../../common/components/forms/FormInput';
import {ButtonBase} from '../../../common/components/helperComponents/ButtonBase';
import {onSubmitFailFocusFieldWithError} from '../../../common/components/helperComponents/ErrorFocus';
import {TooltipIcon} from '../../../common/components/helperComponents/TooltipIcon';
import {DownloadIcon} from '../../../common/components/icons/DownloadIcon';
import {PaperBlock} from '../../../common/components/layout/PaperBlock';
import {PageRoute} from '../../../common/constants';
import {Span} from '../../../common/styledComponents/Span';
import {getSampleImportFile} from '../../../common/utils/CommonUtils';
import {EnvironmentUtils} from '../../../common/utils/EnvironmentUtils';
import {validationSchemaStepOne} from '../../../common/utils/Validations';
import {StepOneQueries} from '../graphql/StepOneQueries';

// Komponenta pro zobrazení vstupní obrazovky zadání emailu,eanu a obce v případě změny
export const StepOne: FC = ({}) => {
    // methods
    const {push} = useRouter();
    const {setActiveStep, setPppData, setFormData, setSuccessStep} = useCommonAction();

    //local variable
    const filePath = getSampleImportFile();

    // form
    const methods = useForm<PppSopDataInput>({
        resolver: yupResolver(validationSchemaStepOne),
        mode: 'onChange',
        defaultValues: {
            cisloSop: '',
            ean: '',
            obec: '',
        },
    });
    const {handleSubmit, formState} = methods;

    // query
    const [loadData, {loading}] = useLazyQuery<Query, PppQueryFormDataArgs>(StepOneQueries.gql.stepOneSendData, {
        onCompleted: (res) => {
            push(PageRoute.OSOBNI_UDAJE);
            setActiveStep(1);
            setSuccessStep(0);
            setFormData({
                zakladniUdaje: {
                    ean: res.prvniParalelniPripojeni.formData.ean,
                    cisloSop: res.prvniParalelniPripojeni.formData.cisloSop,
                    obec: res.prvniParalelniPripojeni.formData.obec,
                },
            });
            setPppData(res.prvniParalelniPripojeni.formData);
        },
    });

    if (loading) {
        return <LoadingDialog open />;
    }

    const handleDownloadFile = () => saveAs(`${window.location.origin}/static${filePath}`, `${filePath.split('/').pop()}`);

    const onSubmit = (input: PppSopDataInput) => {
        // eslint-disable-next-line no-console
        !EnvironmentUtils.isProdPreprod() && console.log('input', input);
        loadData({variables: {input}});
    };

    // eslint-disable-next-line no-console
    !EnvironmentUtils.isProdPreprod() && console.log(`%c 🚨 -> errors: `, 'color: #e13019', formState.errors);

    return (
        <PaperBlock
            titleBlock={<FormattedMessage id="step.one.title" />}
            description={
                <Span sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="body2" color="secondary">
                        <FormattedMessage id="step.one.description" />
                    </Typography>
                    <TooltipIcon text={'form.fileInput.sample.file.title'} placement="right-start" color="primary" fontSize="small" withButton>
                        <IconButton onClick={handleDownloadFile}>
                            <DownloadIcon />
                        </IconButton>
                    </TooltipIcon>
                </Span>
            }
            titleBlockIcon={<InputOutlined color="primary" fontSize="large" />}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onSubmitFailFocusFieldWithError)} noValidate>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput label="ean" name="ean" startAdornment={<ExplicitOutlined />} required />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormInput label="cisloSop" name="cisloSop" startAdornment={<ConfirmationNumberOutlined />} required />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput label="obec" name="obec" startAdornment={<LocationCityOutlined />} required />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ButtonBase hideButton />
                </form>
            </FormProvider>
        </PaperBlock>
    );
};
